<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmactions.title')">
					<v-row no-gutters>
						<v-col cols="12" sm="6" md="4">
							<pui-text-field :label="$t('pmactions.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<pui-text-field
								v-model="model.actioncode"
								:label="$t('pmactions.actioncode')"
								maxlength="20"
								required
								toplabel
							></pui-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmactions.pmorganization')"
								:placeholder="this.$t('pmactions.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmactions.pmorganization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<pui-text-field
								v-model="model.actionurl"
								:label="$t('pmactions.actionurl')"
								maxlength="150"
								required
								toplabel
							></pui-text-field>
						</v-col>
						<v-col cols="12" sm="4" md="2">
							<pui-checkbox
								:label="$t('pmactions.isexternal')"
								v-model="model.isexternal"
								:true-value="true"
								:false-value="false"
								class="mt-4"
							></pui-checkbox>
						</v-col>
						<v-col cols="12" sm="4" md="2">
							<pui-checkbox
								:label="$t('pmactions.datasourcetyperequired')"
								:tooltipDescription="$t('pmactions.tooltipDatasourcetypeRequired')"
								v-model="model.datasourcetyperequired"
								:true-value="true"
								:false-value="false"
								class="mt-4"
							></pui-checkbox>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<pui-select
								v-if="model.datasourcetyperequired"
								attach="pmdatasourcetypeid"
								:label="this.$t('pmdatasourceinstance.pmdatasourcetype')"
								:placeholder="this.$t('pmdatasourceinstance.phdatasourcetype')"
								toplabel
								clearable
								required
								reactive
								:disabled="hasOrganization"
								v-model="model"
								modelName="vluppmdatasourcetype"
								:modelFormMapping="{ pmdatasourcetypeid: 'pmdatasourcetypeid' }"
								:itemsToSelect="datasourcetypeItemsToSelect"
								itemValue="pmdatasourcetypeid"
								itemText="name"
								:fixedFilter="datasourcetypeFilter"
							></pui-select>
						</v-col>
						<v-col cols="12" sm="6" :md="4">
							<v-select
								v-model="model.actiontype"
								class="mt-2 px-1"
								:label="$t('pmactions.actiontype')"
								required
								:hide-details="false"
								:items="actionTypes"
							>
								<template v-slot:item="{ item }">
									<!-- <div @mouseover="debugItem(item)"> -->
									<v-tooltip v-if="item.description" left nudge-left="10" max-width="350" color="var(--gris-secondary)">
										<template v-slot:activator="{ on, attrs }">
											<div class="width-100 height-100" v-bind="attrs" v-on="on">
												<span>{{ $t(item.text) }}</span>
											</div>
										</template>
										<span class="fw-regular fs-regular text-center">{{ $t(item.description) }}</span>
									</v-tooltip>
									<span v-else>{{ $t(item.text) }}</span>
									<!-- </div> -->
								</template>
							</v-select>
						</v-col>
						<v-col cols="12" sm="6" :md="4">
							<v-select
								v-model="model.actionmethod"
								class="mt-2 px-1"
								:label="$t('pmactions.actionmethod')"
								required
								:hide-details="false"
								:items="actionMethods"
							></v-select>
						</v-col>
						<v-col cols="12" sm="6" :md="4">
							<div class="label_container">
								<label class="fixed_label mx-1">
									{{ this.$t('pmseverity.icon') }}
									<span style="color: var(--R-200)">*</span>
								</label>
							</div>
							<v-select
								v-model="model.pmiconid"
								:items="items"
								:placeholder="this.$t('pmseverity.phicon')"
								clearable
								:disabled="formDisabled"
								rounded
								:rules="[rules.required]"
								item-value="pmiconsid"
								persistent-placeholder
								variant="outlined"
								class="pa-0 mx-1 mt-auto"
							>
								<template v-slot:selection="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
								<template v-slot:item="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
							</v-select>
						</v-col>
						<v-col cols="12" sm="6">
							<v-select
								v-model="model.authtype"
								class="mt-2 px-1"
								:label="$t('pmactions.authtype')"
								required
								:hide-details="false"
								:items="authTypes"
							></v-select>
						</v-col>
						<v-col cols="12" sm="6">
							<pui-text-field
								v-model="model.authcredentials"
								:label="$t('pmactions.authcredentials') + ' ' + authHelp"
								maxlength="150"
								:required="model.authtype !== 'none'"
								:disabled="model.authtype === 'none'"
								toplabel
							></pui-text-field>
						</v-col>
						<v-col cols="12" md="6" class="pa-2">
							<pui-field-set-with-info
								:title="$t('pmactions.headers')"
								:tooltip-text="$t('pmactions.headersDescription')"
								:error="errorHeaders"
							>
								<vue-json-editor
									v-model="jsonHeaders"
									:options="options"
									:plus="false"
									disabled
									height="300px"
									@error="handleErrorHeaders"
									@input="handleInputHeaders"
								></vue-json-editor>
							</pui-field-set-with-info>
						</v-col>

						<v-col cols="12" md="6" class="pa-2">
							<pui-field-set-with-info
								:title="$t('pmactions.configuration')"
								:tooltip-text="$t('pmactions.configurationDescription')"
								:downloadText="$t('pmactions.downloadExample')"
								:exampleJson="uiDefinition"
								:actionConfiguration="jsonConfiguration"
								:actionName="model.name"
								downloadFileName="configurationExample.json"
								:previewJSON="$t('pmactions.previewJSONConfiguration')"
								ref="jsonConfigurationFieldset"
								:error="errorConfiguration"
							>
								<!-- <vue-json-editor
									v-model="jsonConfiguration"
									:options="options"
									:plus="false"
									height="300px"
									@error="setConfigurationNull = true"
									@input="onJsonConfigurationChange"
								></vue-json-editor> -->
								<vue-json-editor
									v-model="jsonConfiguration"
									:options="options"
									:plus="false"
									height="300px"
									@error="handleErrorConfiguration"
									@input="handleInputConfiguration"
								></vue-json-editor>
							</pui-field-set-with-info>
						</v-col>

						<v-col cols="12" md="6" class="pa-2">
							<pui-field-set-with-info
								:title="$t('pmactions.successcondition')"
								:tooltip-text="$t('pmactions.successconditionDescription')"
								:downloadText="$t('pmactions.downloadExample')"
								:exampleJson="successDefinition"
								downloadFileName="successDefinitionExample.json"
								:error="errorSuccessCondition"
							>
								<vue-json-editor
									v-model="jsonSuccessCondition"
									:options="options"
									:plus="false"
									height="300px"
									@error="handleErrorSuccessCondition"
									@input="handleInputSuccessCondition"
								></vue-json-editor>
								<!-- <pui-rich-text-editor label="Comments" required v-model="jsonSuccessCondition" id="comments"></pui-rich-text-editor> -->
							</pui-field-set-with-info>
						</v-col>
						<v-col cols="12" md="6" class="pa-2">
							<pui-field-set-with-info
								:title="$t('pmactions.requestbody')"
								:tooltip-text="$t('pmactions.requestbodyDescription')"
								:downloadText="$t('pmactions.downloadExample')"
								:exampleJson="requestBodyDefinition"
								downloadFileName="requestBodyExample.json"
								:error="errorRequestBody"
							>
								<vue-json-editor
									v-model="jsonRequestBody"
									:options="optionsBody"
									:plus="false"
									height="300px"
									@error="handleErrorRequestBody"
									@input="handleInputRequestBody"
								></vue-json-editor>
							</pui-field-set-with-info>
						</v-col>
						<v-col cols="12" sm="6">
							<pui-number-field v-model="model.timeout" :label="$t('pmactions.timeout')" integer required toplabel></pui-number-field>
						</v-col>
						<v-col cols="12" sm="6">
							<pui-number-field
								v-model="model.retrycount"
								:label="$t('pmactions.retrycount')"
								integer
								required
								toplabel
							></pui-number-field>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12">
							<pui-text-area v-model="model.description" :label="$t('pmactions.description')" maxlength="250" toplabel></pui-text-area>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12" sm="6" md="4">
							<pui-checkbox
								:label="$t('pmactions.disabled')"
								v-model="model.disabled"
								:true-value="true"
								:false-value="false"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-col>
					</v-row>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="formatAndSave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import { isSuperAdmin, getIconsType } from '@/api/common';
import VueJsonEditor from '@/components/ui/VueJsonEditor';
import constants from '@/utils/constants';

import PuiFieldSetWithInfo from '../form/fieldset_with_Info/PuiFieldSetWithInfo';

import jsonConfigurationData from '../ui/dynamicControls/userExamples/configurationExample.json';
import jsonSuccessCondition from '../ui/dynamicControls/userExamples/successConditionExample.json';
import jsonRequestBody from '../ui/dynamicControls/userExamples/requestBodyExample.json';

export default {
	name: 'PmActionsForm',
	mixins: [PuiFormMethodsMixin],
	components: {
		VueJsonEditor,
		PuiFieldSetWithInfo
	},
	data() {
		return {
			modelName: 'pmactions',
			isSuperAdmin: false,
			datasourcetypeFilter: null,
			items: [],
			iconSelected: null,
			actionTypes: constants.ACTION_TYPES.map((element) => {
				return { text: this.$t(element.text), value: element.value, description: this.$t(element.description) };
			}),
			actionMethods: constants.ACTION_METHODS.map((element) => {
				return { text: this.$t(element.text), value: element.value };
			}),
			authTypes: constants.AUTH_TYPES.map((element) => {
				return { text: this.$t(element.text), value: element.value };
			}),
			options: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
				/* onChangeText(json) {
					console.log('onChange query', json);
				} */
			},
			optionsBody: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
			},
			setConfigurationNull: false,
			setHeadersNull: false,
			setSuccessConditionNull: false,
			setRequestBodyNull: false,
			jsonHeaders: {},
			jsonConfiguration: {},
			jsonSuccessCondition: {},
			jsonRequestBody: {},
			authHelp: '',
			uiDefinition: jsonConfigurationData,
			successDefinition: jsonSuccessCondition,
			requestBodyDefinition: jsonRequestBody,
			errorHeaders: false,
			errorConfiguration: false,
			errorSuccessCondition: false,
			errorRequestBody: false
		};
	},
	computed: {
		datasourcetypeItemsToSelect() {
			return [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		},
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmactionsid;
		},
		hasOrganization() {
			return !this.model.pmorganizationid;
		}
	},
	watch: {
		'model.actionmethod'(val) {
			console.log('action method watch', val);
			/*if (this.model && val === 'GET') {
				this.model.requestbody = null;
				this.optionsBody.onEditable = () => false;
			} else {
				this.optionsBody.onEditable = () => true;
			}*/
			this.optionsBody.onEditable = () => true;
		},
		'model.pmorganizationid'(val, prev) {
			console.log('pmorganizationid watch', prev, val, this.isUpdateEntry);
			if (this.isSuperAdmin && val && prev) {
				this.model.pmdatasourcetypeid = null;
				this.datasourcetypeFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid || this.userProperties.organizationid }]
				};
			}
			if (!prev && val && this.isUpdateEntry) {
				this.jsonHeaders = JSON.parse(this.model.headers);
				this.jsonConfiguration = JSON.parse(this.model.configuration);
				this.jsonSuccessCondition = JSON.parse(this.model.successcondition);
				this.jsonRequestBody = JSON.parse(this.model.requestbody);
				this.onJsonConfigurationChange(this.jsonConfiguration);
			}
		},
		iconSelected: {
			handler() {
				console.log('iconSelected watch', this.iconSelected);

				/* if (this.isSuperAdmin) {
					this.model.pmiconid == this.iconSelected ? this.iconSelected.pmiconsid : null;
				} */
			},
			deep: true
		},
		'model.authtype'(val, prev) {
			/* if(!val) {
				this.model.authtype = 'none';
			} */
			if (val) {
				switch (val) {
					case 'none':
						this.authHelp = '';
						break;
					case 'basic':
						this.authHelp = this.$t('pmactions.authbasic');
						break;
					case 'token':
						this.authHelp = this.$t('pmactions.authtoken');
						break;
					case 'oauth2':
						this.authHelp = this.$t('pmactions.authoauth2');
						break;
					default:
						this.authHelp = '';
						break;
				}
				if (prev) this.model.authcredentials = null;
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;

			this.model.pmorganizationid = this.userProperties['organizationid'];
		}
	},
	mounted() {
		this.actionTypes = this.actionTypes.map((element) => {
			return { text: this.$t(element.text), value: element.value, description: this.$t(element.description) };
		});
		/* this.actionMethods = this.actionMethods.map((element) => {
			return { text: this.$t(element.text), value: element.value };
		});
		this.authTypes = this.authTypes.map((element) => {
			return { text: this.$t(element.text), value: element.value };
		}); */
		this.items = getIconsType(
			constants.ACTIONS_ICONS_TYPE,
			this.$store.getters.getIcons
		) /* .concat(getIconsType(constants.DEFAULT_DTS_ICONS_TYPE, this.$store.getters.getIcons)) */;
		this.items = this.items.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (!this.isSuperAdmin) {
			this.datasourcetypeFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid || this.userProperties.organizationid }]
			};
		}
	},
	beforeUpdate() {
		/* if (this.isSuperAdmin) {
			this.iconSelected = this.items.find((element) => element.pmiconsid == this.model.pmiconid);
		} else {
			this.iconSelected = this.items.find(
				(element) => element.pmiconsid == getIconsType(constants.DEFAULT_DTS_ICONS_TYPE, this.$store.getters.getIcons)[0].pmiconsid
			);
		} */
	},
	updated() {
		if (this.model && !this.isSuperAdmin) {
			this.model.pmorganizationid = this.userProperties['organizationid'];
		}
		console.log('updated', this.model);
	},
	methods: {
		debugItem(item) {
			console.log('debugItem', item);
		},
		onErrorQuery(e) {
			console.log('error', e, this.jsonHeaders);
		},
		onInputQuery(e) {
			console.log('input query', e);
		},
		itemStyle(item) {
			var urlBaseIcons = this.$store.getters.getUrlBaseIcons;
			//console.log('dev hlp', urlBaseIcons + item.route);
			return 'content: url(' + urlBaseIcons + item.route.trim() + '); margin-right: 1rem; transform: scale(0.7); width: 38px';
		},
		formatAndSave() {
			console.log('formatAndSave debug', this.jsonHeaders, this.jsonConfiguration, this.jsonSuccessCondition, this.jsonRequestBody);
			console.log('formatAndSave', this.setHeadersNull, this.setConfigurationNull, this.setSuccessConditionNull, this.setRequestBodyNull);

			if (this.errorHeaders || this.errorConfiguration || this.errorSuccessCondition || this.errorRequestBody) {
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				this.resetAfterSaveActions();
				return;
			}

			if (this.setHeadersNull) this.model.headers = '{}';
			else this.model.headers = this.jsonHeaders !== null ? JSON.stringify(this.jsonHeaders) : '{}';
			if (this.setConfigurationNull) this.model.configuration = '{}';
			else this.model.configuration = this.jsonConfiguration !== null ? JSON.stringify(this.jsonConfiguration) : '{}';
			if (this.setSuccessConditionNull) this.model.successcondition = '{}';
			else this.model.successcondition = this.jsonSuccessCondition !== null ? JSON.stringify(this.jsonSuccessCondition) : '{}';
			if (this.setRequestBodyNull) this.model.requestbody = '{}';
			else this.model.requestbody = this.jsonRequestBody !== null ? JSON.stringify(this.jsonRequestBody) : '{}';
			console.log('formatAndSave', this.model);

			this.save();
		},
		onJsonConfigurationChange(value) {
			this.setConfigurationNull = false;
			this.jsonConfiguration = value;
			// Actualizar el contenido del preview dinámico
			//this.$refs.jsonConfigurationFieldset.updateSlotContent(value);
		},
		handleErrorConfiguration(v) {
			console.log('handleErrorConfiguration', v, !!v);
			if (v) this.errorConfiguration = true;
			else {
				this.setConfigurationNull = true;
				this.errorConfiguration = false;
			}
		},
		handleErrorSuccessCondition(v) {
			console.log('handleErrorSuccessCondition', v, !!v);
			if (v) this.errorSuccessCondition = true;
			else {
				this.setSuccessConditionNull = true;
				this.errorSuccessCondition = false;
			}
		},
		handleErrorRequestBody(v) {
			console.log('handleErrorRequestBody', v, !!v);
			if (v) this.errorRequestBody = true;
			else {
				this.setRequestBodyNull = true;
				this.errorRequestBody = false;
			}
		},
		handleErrorHeaders(v) {
			console.log('handleErrorHeaders', v, !!v);
			if (v) this.errorHeaders = true;
			else {
				this.setHeadersNull = true;
				this.errorHeaders = false;
			}
		},
		handleInputConfiguration(v) {
			console.log('handleInputConfiguration', v, !!v);
			this.setConfigurationNull = false;
			this.errorConfiguration = false;
			this.jsonConfiguration = v;
		},
		handleInputSuccessCondition(v) {
			console.log('handleInputSuccessCondition', v, !!v);
			this.setSuccessConditionNull = false;
			this.errorSuccessCondition = false;
		},
		handleInputRequestBody(v) {
			console.log('handleInputRequestBody', v, !!v);
			this.setRequestBodyNull = false;
			this.errorRequestBody = false;
		},
		handleInputHeaders(v) {
			console.log('handleInputHeaders', v, !!v);
			this.setHeadersNull = false;
			this.errorHeaders = false;
		}
	}
};
</script>

<style scoped>
fieldset > legend .v-icon {
	font-size: 16px; /* Ajusta según el diseño */
	color: #1976d2; /* Azul primario */
	display: inline-block;
	vertical-align: middle;
	margin-left: 8px;
	cursor: pointer;
}

fieldset > legend .v-icon:hover {
	color: #0d47a1; /* Cambia el color al pasar el ratón */
}
</style>
