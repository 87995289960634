<template>
	<div :class="error ? 'fieldset-container-error' : 'fieldset-container'">
		<fieldset class="px-3">
			<legend>
				<span :class="error ? 'legend-text-error mr-1' : 'legend-text mr-1'">{{ title }}</span>
				<template v-if="tooltipText">
					<v-tooltip top class="custom-tooltip" color="var(--gris-secondary)" max-width="360" nudge-right="25">
						<template v-slot:activator="{ on }">
							<v-icon class="info-tooltip pl-1" size="14" v-on="on">fak fa-line-info-2</v-icon>
						</template>
						<span class="tooltip-text">
							{{ tooltipText }}
						</span>
					</v-tooltip>
				</template>
				<template v-if="exampleJson">
					<a class="example-link" href="#" @click.prevent="downloadExampleJson">{{ downloadText }}</a>
				</template>
				<template v-if="previewJSON">
					<a class="preview-link" href="#" @click.prevent="togglePreview">{{ previewJSON }}</a>
				</template>
			</legend>
			<slot></slot>
		</fieldset>
		<v-dialog v-model="isPreviewOpen" max-width="600px">
			<action-form v-if="dynamicContent" :configuration="dynamicContent">
				<template #title>JSON Preview: {{ actionName }}</template>
				<template #close-button>
					<v-btn width="32" height="32" class="ml-2 feature-btn color-white pa-0" small depressed @click="isPreviewOpen = false">
						<v-icon class="fak fa-solid-close" style="font-size: 0.8rem !important"></v-icon>
					</v-btn>
				</template>
			</action-form>
		</v-dialog>
	</div>
</template>

<script>
import ActionForm from '@/components/ui/dynamicControls/ActionForm.vue';

export default {
	name: 'PuiFieldSetWithInfo',
	components: {
		ActionForm
	},
	props: {
		title: {
			type: String,
			required: true
		},
		tooltipText: {
			type: String,
			required: false,
			default: ''
		},
		exampleJson: {
			type: Object, // La prop debe ser un objeto JSON
			required: false,
			default: null // Por defecto, no hay archivo de ejemplo
		},
		downloadText: {
			type: String,
			required: false,
			default: 'Download example' // Texto por defecto
		},
		downloadFileName: {
			type: String,
			required: false,
			default: ''
		},
		previewJSON: {
			type: String, // El texto para mostrar en el enlace de preview
			required: false,
			default: '' // Si no se pasa, no se muestra el enlace
		},
		actionConfiguration: {
			type: Object,
			required: false,
			default: null
		},
		actionName: {
			type: String,
			required: false,
			default: ''
		},
		error: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			isPreviewOpen: false,
			slotContent: null, // Contenido dinámico del slot
			dynamicContent: null
		};
	},
	computed: {
		formattedJson() {
			// Formatea el JSON para mostrarlo en la previsualización
			return this.exampleJson ? JSON.stringify(this.exampleJson, null, 2) : 'No JSON available';
		}
		/* previewContent() {
			// Muestra el contenido dinámico o el texto por defecto
			return this.slotContent ? JSON.stringify(this.slotContent, null, 2) : 'No content available';
		} */
	},
	watch: {
		isPreviewOpen: {
			handler(val) {
				if (val) {
					if (this.actionConfiguration && Object.keys(this.actionConfiguration).length > 0) this.dynamicContent = this.actionConfiguration;
				} else this.dynamicContent = null;
			},
			immediate: true
		}
	},
	methods: {
		togglePreview() {
			this.isPreviewOpen = !this.isPreviewOpen;
		},
		downloadExampleJson() {
			// Usar el nombre del archivo pasado por la prop `downloadFileName`
			const fileName = this.downloadFileName || 'default-example.json'; // Si no se pasa nombre, usar el predeterminado

			// Utiliza la prop `exampleJson` directamente
			const blob = new Blob([JSON.stringify(this.exampleJson, null, 2)], { type: 'application/json' });

			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = fileName; // Nombre dinámico para el archivo descargado
			link.click();
			URL.revokeObjectURL(link.href);
		},
		updateSlotContent(content) {
			this.slotContent = content;
		},
		downloadExampleJson_old() {
			const exampleJson = {
				fields: [
					{ label: 'Nombre', type: 'text', required: true },
					{ label: 'Edad', type: 'number', required: false },
					{ label: 'Género', type: 'select', options: ['Masculino', 'Femenino', 'Otro'], required: true }
				]
			};

			const blob = new Blob([JSON.stringify(exampleJson, null, 2)], { type: 'application/json' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'formulario-ejemplo.json';
			link.click();
			URL.revokeObjectURL(link.href);
		}
	}
};
</script>

<style scoped>
.info-tooltip {
	cursor: pointer;
}

.fieldset-container fieldset {
	border: 1px solid #ccc;
	padding: 4px;
	border-radius: 4px;
}

.fieldset-container-error fieldset {
	border: 1px solid var(--validation-error);
	padding: 4px;
	border-radius: 4px;
}

.legend-text-error {
	color: var(--validation-error);
}

.tooltip-text {
	color: #ffffff !important;
}

.v-tooltip__content {
	background-color: var(--azul-primary-100) !important;
	border-color: var(--azul-primary-100) !important;
}

.example-link {
	margin-left: 10px;
	color: var(--azul-primary-100);
	text-decoration: underline;
	cursor: pointer;
}

.preview-link {
	margin-left: 10px;
	color: var(--azul-primary-100);
	text-decoration: underline;
	cursor: pointer;
}

.json-preview {
	background-color: #f9f9f9;
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 4px;
	font-family: monospace;
	white-space: pre-wrap; /* Para evitar desbordamiento horizontal */
}

.feature-btn {
	background-color: var(--azul-primary-100) !important;
	min-width: 32px !important;
}
</style>
